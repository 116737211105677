<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card>
        <v-progress-linear
          id="signInLoadingIndicator"
          :active="loading"
          :indeterminate="loading"
          absolute
          top
          color="primary"
        />
        <v-card-text class="text-center">
          <v-avatar
            size="60"
            class="mb-4"
            rounded
          >
            <img
              src="/assets/logo/ktgLogo.png"
              alt=""
            >
          </v-avatar>

          <h6 class="text--disabled font-weight-medium mb-10">
            Sign in to your account
          </h6>
          <v-form>
            <v-text-field
              v-model="email"
              label="Email"
              :rules="emailRules"
              required
            />

            <v-text-field
              v-model="ePassword"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-2"
              label="Password"
              :counter="10"
              :rules="nameRules"
              @click:append="show = !show"
            />
            <v-checkbox
              v-model="rememberMe"
              label="Remember this computer"
            />
            <v-btn
              class="mb-4"
              block
              color="primary"
              dark
              @click="formSubmit"
            >
              <v-icon left>
                mdi-login
              </v-icon>
              Sign In
            </v-btn>
            <div class="error" v-if="error">{{ error }}</div>
            <div class="d-flex justify-around flex-wrap">
              <v-btn
                text
                small
                color="primary"
                class="mb-2"
                to="/app/sessions/forgot"
              >
                Forgot Password
              </v-btn>
              <v-btn
                text
                small
                disabled
                color="primary"
                to="/app/sessions/sign-up"
              >
                Create New Account
              </v-btn>
              <small class="ml-3 grey--text">New sign ups are closed for 2024</small>
            </div>
          </v-form>
        </v-card-text>
      </base-card>
    </div>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="color"
      bottom right
    >
      {{ message }}
      <v-btn
        color="white"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
  import firebase from 'firebase/app'
  import { mapGetters, mapActions } from 'vuex'
  import { Snackbar } from 'vuetify';

  export default {
    name: 'Login',
    metaInfo: {
      title: 'Sign In',
    },
    data () {
      return {
        show: false,
        password: 'Password',
        rememberMe: true,
        checkbox2: false,
        email: '',
        ePassword: '',
        snackbar: false,
        message: 'Login Successful',
        timeout: 3000,
        color: 'success',
        loading: false,
        emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        nameRules: [
          (v) => !!v || 'Password is required',
          (v) =>
            (v && v.length <= 10) ||
            'Name must be less than 10 characters',
        ],
      }
    },
    computed: {
      ...mapGetters(['loggedInUser', 'error']),
    },
    methods: {
      ...mapActions(['login']),
      // login: function() {
      //   firebase.auth().signInWithEmailAndPassword(this.email, this.ePassword)
      //     .then(
      //       user => {
      //         // console.log(user);
      //         this.loading = true;
      //         this.$router.push('/');

      //       },
      //       err => {

      //         // alert(err);

      //       }
      //     )
      // },
      formSubmit () {
        this.login({ email: this.email, password: this.ePassword, rememberMe: this.rememberMe })
      }
    },
    watch: {
      // loading (val) {
      //   if (!val) return
      //   setTimeout(() => (this.loading = false), 2000)
      // },
      loggedInUser (val) {
        if (val && val.userDetails.id) {
          //this.makeToast("success", "Successfully Logged In");
          this.loading = true
          setTimeout(() => {
            this.$router.push('/')
          }, 500)
        }
      },
      error (val) {
        if (val != null) {
          // this.makeToast("warning", val.message);
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
.page-wrap {
    background-color: #242939 !important;
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}
.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
</style>
